import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/backoffice/login',
    name: 'Login',
    component: () => import('../views/Backoffice/BackofficeLogin.vue')
  },
  {
    path: '/backoffice',
    name: 'Login',
    component: () => import('../views/Backoffice/BackofficeLogin.vue')
  },
  {
    path: '/backoffice/home',
    name: 'Home',
    component: () => import('../views/Backoffice/BackofficeHome.vue')
  },
  {
    path: '/backoffice/logout',
    name: 'Logout',
    component: () => import('../views/Backoffice/BackofficeLogout.vue')
  },
  {
    path: '/backoffice/backoffice_statistic',
    name: 'Statistict',
    component: () => import('../views/Backoffice/BackofficeStatistic.vue')
  },
  {
    path: '/backoffice/backoffice_kinder_gardens_statistic',
    name: 'BackofficeKinderGardensStatistic',
    component: () => import('../views/Backoffice/BackofficeKinderGardensStatistic.vue')
  },
  {
    path: '/backoffice/backoffice_parents_statistic',
    name: 'BackofficeParentsStatistic',
    component: () => import('../views/Backoffice/BackofficeParentsStatistic.vue')
  },
  {
    path: '/backoffice/backoffice_admin_users',
    name: 'BackofficeAdminUsers',
    component: () => import('../views/Backoffice/BackofficeAdminUsers.vue')
  },
  {
    path: '/backoffice/backoffice_users',
    name: 'BackofficeUsers',
    component: () => import('../views/Backoffice/BackofficeUsers.vue')
  },
  {
    path: '/backoffice/backoffice_kinder_gardens',
    name: 'BackofficeKinderGardens',
    component: () => import('../views/Backoffice/BackofficeKinderGardens.vue')
  },
  {
    path: '/backoffice/backoffice_add_new_parent',
    name: 'BackofficeAddParent',
    component: () => import('../views/Backoffice/BackofficeAddParent.vue')
  },
  {
    path: '/backoffice/wysiwyg',
    name: 'Wysiwyg',
    component: () => import('../views/Backoffice/BackofficeWysiwig.vue')
  },
  {
    path: '/backoffice/forgot_password',
    name: 'BackofficeForgotPassword',
    component: () => import('../views/Backoffice/BackofficeForgotPassword.vue')
  },
  {
    path : '/backoffice/backoffice_user_delete',
    name : 'BackofficeUserDelete',
    component : () => import('../views/Backoffice/BackofficeUserDelete.vue')
  },
  {
    path : '/backoffice/backoffice_parents',
    name : 'BackofficeParents',
    component : () => import('../views/Backoffice/BackofficeParents.vue')
  },
  {
    path : '/backoffice/backoffice_delete_parent',
    name : 'BackofficeDeleteParent',
    component : () => import('../views/Backoffice/BackofficeDeleteParent.vue')
  },
  {
    path : '/backoffice/backoffice_user_edit',
    name : 'BackofficeUserEdit',
    component : () => import('../views/Backoffice/BackofficeUserEdit.vue')
  },
  {
    path : '/backoffice/backoffice_edit_kindergarten',
    name : 'BackofficeEditKindergarten',
    component : () => import('../views/Backoffice/BackofficeEditKindergarten.vue')
  },
  {
    path : '/backoffice/backoffice_edit_kindergarten_location',
    name : 'BackofficeEditKindergartenLocation',
    component : () => import('../views/Backoffice/BackofficeEditKindergartenLocation.vue')
  },
  {
    path : '/backoffice/backoffice_delete_kindergarten_step_1',
    name : 'BackofficeDeleteKindergarten',
    component : () => import('../views/Backoffice/BackofficeDeleteKindergarten.vue')
  },
  {
    path : '/backoffice/backoffice_edit_location',
    name : 'BackofficeLocation',
    component : () => import('../views/Backoffice/BackofficeLocation.vue')
  },
  {
    path : '/backoffice/backoffice_user_change_password',
    name : 'BackofficeUserChangePassword',
    component : () => import('../views/Backoffice/BackofficeUserChangePassword.vue')
  },
  {
    path : '/backoffice/file_uploader',
    name : 'BackofficeFileUploader',
    component : () => import('../views/Backoffice/BackofficeFileUploader.vue')
  },
  {
    path : '/backoffice/cloudinary_file_uploader',
    name : 'BackofficeCloudinaryFileUploader',
    component : () => import('../views/Backoffice/BackofficeCloudinaryFileUploader.vue')
  },
  {
    path : '/backoffice/s3_file_uploader',
    name : 'BackofficeS3FileUploader',
    component : () => import('../views/Backoffice/BackofficeS3FileUploader.vue')
  },
  {
    path : '/backoffice/payme',
    name : 'BackofficePayme',
    component : () => import('../views/Backoffice/BackofficePayme.vue')
  },
  {
    path : '/backoffice/payme_ty',
    name : 'BackofficePaymeTy',
    component : () => import('../views/Backoffice/BackofficePaymeTy.vue')
  },
  {
    path : '/backoffice/payme_order_delete',
    name : 'BackofficePaymeOrderDelete',
    component : () => import('../views/Backoffice/BackofficePaymeOrderDelete.vue')
  },
  {
    path : '/backoffice/payme_order_refund',
    name : 'BackofficePaymeOrderRefund',
    component : () => import('../views/Backoffice/BackofficePaymeOrderRefund.vue')
  },
  {
    path : '/backoffice/payme_order_ty',
    name : 'BackofficePaymeOrderTy',
    component : () => import('../views/Backoffice/BackofficePaymeOrderTy.vue')
  },
  {
    path : '/backoffice/fixed_sms',
    name : 'BackofficeFixedSms',
    component : () => import('../views/Backoffice/BackofficeFixedSms.vue')
  },
  {
    path : '/backoffice/backoffice_add_sms',
    name : 'BackofficeAddSms',
    component : () => import('../views/Backoffice/BackofficeAddSms.vue')
  },
  {
    path : '/backoffice/backoffice_sms_delete',
    name : 'BackofficeDeleteSms',
    component : () => import('../views/Backoffice/BackofficeDeleteSms.vue')
  },
  {
    path: '/',
    component : () => import('../views/Hp.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component : () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
